<template>
  <div>
    <section
      class="bg-gradient-to-b relative from-white to-gray-100 py-8 sm:py-16"
    >
      <div class="absolute inset-0">
        <img
          class="w-full h-full object-cover object-top"
          src="/img/pages/ai_form_builder/background-pattern.svg"
          alt="Page abstract background"
        >
      </div>

      <div
        class="px-4 sm:px-6 lg:px-8 max-w-7xl mx-auto relative -mb-32 md:-mb-52 lg:-mb-72"
      >
        <div class="flex justify-center mb-5">
          <div
            class="relative flex items-center shadow-sm bg-white gap-x-4 rounded-full px-4 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20"
          > {{$t('index.title')}}

          </div>
        </div>
        <div class="max-w-4xl mx-auto text-center">
          <h1
            class="text-4xl sm:text-5xl lg:text-6xl font-semibold text-gray-900 tracking-tight"
          >
            {{$t('index.desc_1')}}
            <span
              class="bg-clip-text text-transparent bg-gradient-to-r from-blue-600 to-blue-400"
            >{{$t('forms')}}</span>
            <br>
            {{$t('index.desc_2')}}
          </h1>
          <p
            class="mt-4 sm:mt-5 text-base leading-7 sm:text-xl sm:leading-9 font-medium text-gray-500"
          >
            {{$t('index.description')}}
          </p>

          <div class="mt-8 flex justify-center">
            <v-button
              v-if="!authenticated && !isSelfHosted"
              class="mr-1"
              :to="{ name: 'forms-create-guest' }"
              :arrow="true"
            >
              {{$t('make')}} {{$t('forms')}}
            </v-button>
            <v-button
              v-if="authenticated"
              class="mr-1"
              :to="{ name: 'forms-create' }"
              :arrow="true"
            >
              {{$t('make')}} {{$t('forms')}}
            </v-button>
          </div>

        </div>

<!--        <div-->
<!--          class="w-full mt-12 relative px-6 mx-auto max-w-4xl sm:px-10 lg:px-0 z-10 flex items-center justify-center"-->
<!--        >-->
<!--          <div-->
<!--            class="-m-2 rounded-xl bg-blue-900/5 p-2 backdrop-blur-sm ring-1 ring-inset ring-blue-900/10 lg:-m-4 lg:rounded-2xl lg:p-4 w-full"-->
<!--          >-->

<!--          </div>-->
<!--        </div>-->
      </div>
    </section>

  </div>

</template>

<script>
import { computed } from "vue"
import { useAuthStore } from "../stores/auth"
import Features from "~/components/pages/welcome/Features.vue"
import MoreFeatures from "~/components/pages/welcome/MoreFeatures.vue"
import PricingTable from "../components/pages/pricing/PricingTable.vue"
import AiFeature from "~/components/pages/welcome/AiFeature.vue"
import TemplatesSlider from "../components/pages/welcome/TemplatesSlider.vue"
import opnformConfig from "~/opnform.config.js"

export default {
  components: {
    Features,
    MoreFeatures,
    PricingTable,
    AiFeature,
    TemplatesSlider,
  },
  layout: "default",

  setup() {
    const authStore = useAuthStore()
    defineRouteRules({
      swr: 3600,
    })

    return {
      authenticated: computed(() => authStore.check),
      config: opnformConfig,
      runtimeConfig: useRuntimeConfig(),
    }
  },

  data: () => ({}),

  computed: {
    configLinks() {
      return this.config.links
    },
    paidPlansEnabled() {
      return this.runtimeConfig.public.paidPlansEnabled
    },
    isSelfHosted(){
      return useRuntimeConfig().public.selfHosted
    },
    isInvited(){
      return this.$route.query?.email && this.$route.query?.invite_token
    }
  },
}
</script>

<style lang="scss" scoped>
.customer-logo-container {
  max-width: 130px;
  width: 100%;
}

.ticks {
  color: #2563eb;
}

@screen md {
  #macbook-video {
    position: absolute;
    max-width: 84.8% !important;
    right: 0px;
    top: 6.8%;
  }
}
</style>
